@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

    select {
        @apply outline-none ring-0 focus:outline-none focus:ring-0;
    }

    select:focus {
        @apply outline-none ring-0 focus:outline-none focus:ring-0;
    }

}

@layer components {

    .cadInput {
        @apply rounded-full h-10 mb-0 text-sm text-center focus:outline-none focus:ring-0 focus:border-gray-300 border-gray-300 border-2 text-gray-600 placeholder:text-gray-400
    }

    .labelRegister {
        @apply text-gray-500 font-bold text-[12px] mt-2
    }

    .divTable {
        @apply overflow-x-scroll whitespace-nowrap
    }

    .divTable::-webkit-scrollbar {
        display: block;
        height: .5rem;
        padding: 1rem;
    }

    .divTable::-webkit-scrollbar-thumb {
        background-color: rgb(0, 174, 255);
        border-radius: 1rem;
        margin: 2rem;
    }

    .divTable::-webkit-scrollbar-track {
        padding: 1rem;
    }

    .tableFilter th {
        @apply border-2 border-gray-300 text-[13px] lg:text-[15px] text-gray-500 font-bold bg-gray-200 h-10 px-2 py-2
    }

    .tableFilter {
        @apply min-w-full border-collapse after:overflow-hidden overflow-hidden border-2 border-gray-700
    }

    .tableFilter td {
        @apply text-[13px] lg:text-[15px] p-2 border-2 border-gray-200 text-gray-800 h-10
    }

    .tableFilter tr {
        @apply border-2 border-gray-500
    }

    .containerProdsHidden {
        display: none;
    }

    .containerProds {
        @apply w-full flex flex-col gap-4
    }

}

@keyframes shrinkWidth {
    0% {
        width: 85%
    }

    100% {
        width: 15%
    }
}

@keyframes shrinkHeight {
    0% {
        height: 100%;
    }

    100% {
        height: 0%;
        transform: translateY(-150%);
        display: none;
    }
}

.shrinkWidthClass {
    animation: shrinkWidth 1s ease-in-out forwards;
}

.shrinkHeightClass {
    animation: shrinkHeight 1.5s ease-in-out forwards;
}

@keyframes increaseWidth {
    0% {
        width: 15%
    }

    100% {
        width: 85%
    }
}

@keyframes increaseHeight {
    0% {
        height: 0%;
        transform: translateY(-150%);
    }

    100% {
        height: 0%;
        transform: translateY(0);
    }
}

.increaseWidthClass {
    animation: increaseWidth 1s ease-in-out forwards;
}

.increaseHeightClass {
    animation: increaseHeight 1s ease-in-out forwards;
}

/* Estilo para o placeholder (primeira opção) quando está visível no select */
#mySelect option[disabled] {
    color: #9CA3AF;
    /* Cor personalizada para o placeholder */
}

/* Estilo para o select quando a primeira opção está selecionada */
#mySelect:valid {
    color: #000;
    /* Cor do texto para as opções normais */
}

/* Estilo para o select quando a opção desativada está selecionada */
#mySelect:invalid {
    color: #9CA3AF;
    /* Cor do texto quando o placeholder está selecionado */
}