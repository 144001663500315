.optionsCheckPricingContainer,
.optionsPricingContainer {
    max-width: 100%;
    min-width: 80%;
    height: 3rem;
    display: flex;
    margin: 11rem 2rem 1rem;
    gap: 1rem;
    justify-content: center;
    justify-content: center;
}

.optionsChoiseContainer {
    max-width: 100%;
    min-width: 80%;
    height: 2.5rem;
    display: flex;
    margin: 11rem 2rem 1rem;
    justify-content: flex-start;
    margin-bottom: -1rem;
    margin-left: 1rem;
    gap: .1rem;
}

.optionsChoiseContainer button {
    background-color: rgb(0, 174, 239);
    width: 18%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFF;
    padding: 0 .1rem;
    height: 2.5rem;
    
}

.optionsChoiseContainer button.searchButton {
    border-radius: .75rem 0 0;
}

.optionsChoiseContainer button.statusButton {
    border-radius: 0 .75rem 0 0; 
}

.optionsPricingContainer {
    margin: 0 1.5rem;
}

.uploadInput {
    height: 2.5rem;
    flex: 1;
    width: 100%;
    background: red;
}

.table::-webkit-scrollbar {
    display: block;
    height: .5rem;
    padding: 1rem;
}

.table::-webkit-scrollbar-thumb {
    background-color: rgb(0, 174, 255);
    border-radius: 1rem;
    margin: 2rem;
}

.table::-webkit-scrollbar-track {
    background-color: #f0f0f0;
}

.cardsContainer, .cardsStatusContainer {
    gap: 1rem;
    height: max-content;
    display: flex;
    min-height: 30vh;
    flex-wrap: nowrap;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 3rem 1rem;
    border-radius: 0  0 .75rem .75rem;
    background-color: rgb(242, 242, 242);
    border: 1px solid rgb(185, 185, 185);
}

.cardsStatusContainer {
    /* align-items: flex-start; */
    justify-content: flex-start;
}

.cards {
    display: flex;
    width: 100%;
    gap: 1rem;
    height: max-content;
}

.totalFound {
    padding: 1rem;
    gap: 1rem;
    min-width: 27rem;
    max-width: 27rem;
    display: flex;
    padding: 0rem;
    font-weight: 600;
    font-size: .85rem;
    align-items: center;
    border-radius: 10rem;
    justify-content: center;
    color: rgb(87, 87, 87);
    background-color: rgba(0, 0, 0, 0.08);
}

.optionPricingCheck,
.buttonPricingCheck {
    display: flex;
    height: 3rem;
    min-width: 25%;
    max-width: 35%;
    cursor: pointer;
    padding: 0 1rem;
    color: #252028;
    border-radius: 2rem;
    align-items: center;
    background: #00AEEF;
    justify-content: center;
}

.input {
    flex: 1;
    border-radius: 100%;
    border: 1px solid black;
    height: 3rem;
}

.buttonPricingCheck {
    color: white;
    font-size: 0.8rem;
}

.buttonPricingCheck:hover {
    background-color: #0088b9;
}

.updatedSpreadsheetButton {
    flex: 1;
    border-radius: 10rem;
    height: 3rem;
    border: dashed gray;
    color: gray;
    background-color: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.uploaderButton {
    background: none;
    color: gray;
    outline: none;
    border: none;
    width: 100%;
}

.uploaderButton:hover,
.uploaderButton:focus {
    border: none;
    outline: none;
}

.updatedSpreadsheetSendButton {
    background-color: rgb(0, 174, 239);
    width: 10%;
    border-radius: 2rem;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFF;
    padding: 0 .1rem;
    height: 2.5rem;
}

.spreadsheetUpdate {
    gap: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 1rem 10rem 1rem 1rem; */
    border-radius: .75rem;
}
.spreadsheetStatus {
    background-color: #3466AA;
    padding: .25rem 1rem;
    color: white;
    border-radius: 2rem;
}

@media only screen and (max-width: 990px) {

    .totalFound {
        max-width: 100%;
        min-width: 100%;
        height: 3rem;
    }

    .cardsContainer {
        gap: 1rem;
    }

    .optionsCheckPricingContainer {
        margin-top: 15rem;
        flex-direction: column;
        /* height: 7rem; */
    }

    .optionsPricingContainer {
        flex-direction: column;
        /* height: 7rem; */
        width: 100%;
        margin: 0;
    }

    .optionPricingCheck,
    .buttonPricingCheck {
        min-width: 100%;
        height: 3rem;
    }

    .cardsContainer {
        flex-wrap: nowrap;
        border-radius: 0 0 .75rem .75rem;
        min-height: 30vh;
        height: max-content;
    }

    .cards {
        flex-wrap: nowrap;
        flex-direction: column;
        height: max-content;
        
    }

    .updatedSpreadsheetButton {
        width: 100%;
    }

    .optionsChoiseContainer {
        padding: 0rem;
        margin: 1.4rem;
        margin-bottom: -1.3rem;
    }
    

    .optionsChoiseContainer button {
        width: 100%;
    }

    .updatedSpreadsheetButton {
        height: 7rem;
        flex-shrink: 1;
        flex-grow: 1;
    }

    .spreadsheetUpdate {
        width: 100%;
        text-align: center;
        align-items: center; 
        padding: 1rem .5rem;
        justify-content: center;
    }
    
    .cardsStatusContainer {
        border-radius: none;
    }
}