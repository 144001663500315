.chip {
    justify-content: center;
    @media (min-width: 902px) {
        gap: 10px;
    };
    @media (max-width: 901px) {
        flex-direction: column;
        align-items: center;
    }
}

.chip > div {
    max-width: 20%;

    @media (max-width: 901px) {
        max-width: 90%;
    }
}

@keyframes fadeMe {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}

.animationOption {
    animation: fadeMe 3s;
}

.optionsCheckContainer {
    width: 100%;
    height: 2.5rem;
    /* margin-top: 3rem; */
    display: flex;
    gap: .5rem;
}

.optionCheck {
    background-color: #006991;
    width: 50%;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: start;
    color: #FFFF;
    padding: 0 .1rem;
}

@media only screen and (max-width: 990px) {
    .optionsCheckContainer {    
        flex-direction: column;
    }
    .optionCheck {
        width: 100%;
    }
} 