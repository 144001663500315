@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

    .cadInput {
        @apply rounded-full h-10 mb-0 text-sm text-center focus:outline-none focus:ring-0 focus:border-gray-300 border-gray-300 border-2 text-gray-600 placeholder:text-gray-400
    }

    .labelRegister {
        @apply text-gray-500 font-bold text-[12px] mt-2
    }

    .divTable {
        @apply overflow-x-scroll whitespace-nowrap
    }

    .divTable::-webkit-scrollbar {
        display: block;
        height: .5rem;
        padding: 1rem;
    }

    .divTable::-webkit-scrollbar-thumb {
        background-color: rgb(0, 174, 255);
        border-radius: 1rem;
        margin: 2rem;
    }

    .divTable::-webkit-scrollbar-track {
        padding: 1rem;
    }

    .tableFilter th {
        @apply border-2 border-gray-300 text-[13px] text-gray-700 p-2 font-bold bg-gray-200 h-10
    }

    .tableFilter {
        @apply min-w-full border-collapse after:overflow-hidden overflow-hidden
    }

    .tableFilter td {
        @apply text-[13px] p-2 border-2 border-gray-200 text-gray-800 h-10
    }

    .tableFilter tr {
        @apply border-2 border-gray-500
    }

}

@keyframes shrinkWidth {
    0% {
        width: 85%
    }

    100% {
        width: 15%
    }
}

.shrinkWidthClass {
    animation: shrinkWidth 1s ease-in-out forwards;
}

@keyframes increaseWidth {
    0% {
        width: 15%
    }

    100% {
        width: 85%
    }
}

.increaseWidthClass {
    animation: increaseWidth 1s ease-in-out forwards;
}

/* Estilo para o placeholder (primeira opção) quando está visível no select */
#mySelect option[disabled] {
    color: #9CA3AF;
    /* Cor personalizada para o placeholder */
}

/* Estilo para o select quando a primeira opção está selecionada */
#mySelect:valid {
    color: #000;
    /* Cor do texto para as opções normais */
}

/* Estilo para o select quando a opção desativada está selecionada */
#mySelect:invalid {
    color: #9CA3AF;
    /* Cor do texto quando o placeholder está selecionado */
}