.switches-container {
    width: 16rem;
    position: relative;
    display: flex;
    padding: 0;
    position: relative;
    background: #00AEEF;
    line-height: 3rem;
    border-radius: 3rem;
    margin-left: auto;
    margin-right: auto;
}

/* input (radio) for toggling. hidden - use labels for clicking on */
.switches-container input {
    visibility: hidden;
    position: absolute;
    top: 0;
}

/* labels for the input (radio) boxes - something to click on */
.switches-container label {
    width: 50%;
    padding: 0;
    margin: 0;
    text-align: center;
    cursor: pointer;
    color: #F1F1F1;
}

/* switch highlighters wrapper (sliding left / right) 
    - need wrapper to enable the even margins around the highlight box
*/
.switch-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    padding: 0.15rem;
    z-index: 0;
    transition: transform .5s cubic-bezier(.77, 0, .175, 1);
    /* transition: transform 1s; */
}

/* switch box highlighter */
.switch {
    border-radius: 3rem;
    background: #F1F1F1;
    height: 100%;
}

/* switch box labels
    - default setup
    - toggle afterwards based on radio:checked status 
*/
.switch div {
    width: 100%;
    text-align: center;
    opacity: 0;
    display: block;
    color: #00AEEF;
    transition: opacity .2s cubic-bezier(.77, 0, .175, 1) .125s;
    will-change: opacity;
    position: absolute;
    top: 0;
    left: 0;
}

/* slide the switch box from right to left */
.switches-container input:nth-of-type(1):checked~.switch-wrapper {
    transform: translateX(0%);
}

/* slide the switch box from left to right */
.switches-container input:nth-of-type(2):checked~.switch-wrapper {
    transform: translateX(100%);
}

/* toggle the switch box labels - first checkbox:checked - show first switch div */
.switches-container input:nth-of-type(1):checked~.switch-wrapper .switch div:nth-of-type(1) {
    opacity: 1;
}

/* toggle the switch box labels - second checkbox:checked - show second switch div */
.switches-container input:nth-of-type(2):checked~.switch-wrapper .switch div:nth-of-type(2) {
    opacity: 1;
}

.buttonSubmit {
    width: 60% !important;
    border-radius: 30px!important;
    padding: 8px 70px!important;
    background-color: #00AEEF!important;
    color: #F1F1F1!important;
    cursor: pointer!important;
    -webkit-transition: all .5s;
    transition: all .5s;
}

@media (max-width:900px){
    .buttonSubmit {
        width: 90% !important; 
    }

    .switches-container {
        margin: 0;
        width: 100%;
    }
}

.imageBack {
    background: linear-gradient(rgba(0,0,0,0.90), rgba(0,0,0,0.75)), url(/src/images/trator.jpg);
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
}

.imageBack2 {
    background: linear-gradient(rgba(0,0,0,0.90), rgba(0,0,0,0.75)), url(/src/images/trator2.jpg);
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover
}