.pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonPag {
    background: #00AEEF;
    color: white;
    border: white;
    margin: 0.1rem;
    border-radius: .2rem;
    padding: .5rem 1rem;
    height: 1.75rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    font-weight: 700;
}

.numbersOfPage {
    display: flex;
}

.buttonPag:focus {
    background: #0080af;
}