.cardModal {
    background: white; 
    padding: 3rem 1rem; 
    height: 75vh;
    width: 80%; 
    border-radius: 15px; 
    display: flex; 
    align-items: center; 
    justify-content: space-around; 
    flex-direction: column;
    overflow-y: scroll;
}

.inputsContainer {
    background: white;
    width: 100%;
    padding: 2rem;
    border-radius: 15px;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
}

.visualizationCard {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: start;
    width: 40%;
}

.inputDiv input {
    background-color: white;
}
.titleEdit { 
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 5rem; 
}

::-webkit-scrollbar {
    display: none;
}

.buttonSaveEdit {
    width: 60%;
    background: #00BDFF;
    padding: .4rem;
    border: none;
    border-radius: 15rem;
    color: white;
}

@media only screen and (max-width: 990px) {

    .visualizationCard {
        width: 100%;
    }

    .cardModal { 
        width: 90%;
        height: 80vh;
        max-height: 90vh; 
    }

    .inputDiv {
        width: 100%;
    }

    input {
        width: 100%
    }

    .titleEdit { 
        width: 90%; 
        padding: 0;
    }

    .buttonSaveEdit {
        width: 100%;
        padding: 1rem 0;
    }
    
}