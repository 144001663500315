.imageBack {
    background: linear-gradient(rgba(0,0,0,0.95), rgba(0,0,0,0.85)), url(/src/images/helicóptero.jpg);
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
}
.buttonSend {
    width: 100% !important;
} 

