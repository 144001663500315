.imageBack {
    background: linear-gradient(rgba(0,0,0,0.95), rgba(0,0,0,0.80)), url(/src/images/carroceria.jpg);
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover
}

.imageBackMobile {
    background: linear-gradient(rgba(0,0,0,0.95), rgba(0,0,0,0.80)), url(/src/images/carroceria\ copy.jpg);
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover
}

.zeroPadding > ul {
    padding: 0;
}

.buttonSend {
    width: 100% !important;
}