.cardModal {

    background: white; 
    padding: 3rem 1rem; 
    height: 75vh;
    width: 80%; 
    border-radius: 15px; 
    display: flex; 
    align-items: center; 
    justify-content: space-around; 
    flex-direction: column;
    overflow-y: scroll;

}

.inputsContainer {
    background: white;
    width: 100%;
    padding: 1rem 5rem;
    border-radius: 15px;
    display: flex;
    align-content: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
}

.inputDiv {
    width: 20%;
    padding: .2rem 1rem;
    display: flex;
    align-items: center;
    border-radius: .5rem;
    flex-direction: column;
    justify-content: center;
    border: 1px solid rgb(134, 134, 134);
}

.titleEdit { 
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 5rem; 
}

::-webkit-scrollbar {
    display: none;
}

.buttonOkDoubleChassi {
    width: 20%;
    background: #00BDFF;
    padding: .4rem;
    border: none;
    border-radius: 15rem;
    color: white;
}

.downloadIcon {
    color: white;
}

@media only screen and (max-width: 1300px) {
    .inputDiv {
        width: 25%;
    }
}

@media only screen and (max-width: 990px) {

    .cardModal { 
        height: 80vh;
        width: 90%;
    }

    .inputDiv {
        width: 100%;
    }

    input {
        width: 100%
    }

    .titleEdit { 
        width: 100%;
        padding: 0 2rem 2rem;
    }

    .inputsContainer {
        padding: 0rem 2rem;
    }

    .buttonOkDoubleChassi {
        width: 100%;
    }
    
}